'use client'

import { useKeenSlider, KeenSliderOptions } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { CSS_BREAKPOINTS } from '@/constants'
import { useEffect, useState } from 'react'

type Props = {
  children?: React.ReactNode
  options?: KeenSliderOptions
  ignoreDefaultBreakpoints?: boolean
  renderNavigation?: (
    slideLeft: () => void,
    slideRight: () => void
  ) => React.ReactNode
}

export const carouselBreakpoints = {
  small: `(max-width: ${CSS_BREAKPOINTS.small})`,
  medium: `(max-width: ${CSS_BREAKPOINTS.medium})`,
  large: `(max-width: ${CSS_BREAKPOINTS.large})`,
  xlarge: `(max-width: ${CSS_BREAKPOINTS.xlarge})`,
}

const defaultBreakpoints: KeenSliderOptions['breakpoints'] = {
  [carouselBreakpoints.xlarge]: {
    slides: {
      perView: 3,
      spacing: 20,
    },
  },
  [carouselBreakpoints.large]: {
    slides: {
      perView: 3,
      spacing: 20,
    },
  },
  [carouselBreakpoints.medium]: {
    slides: {
      perView: 2,
      spacing: 20,
    },
  },
  [carouselBreakpoints.small]: {
    slides: {
      origin: 'center',
      perView: 1.2,
      spacing: 20,
    },
  },
}

/**
 * all children must have className 'keen-slider__slide'
 */
export const Slider = (props: Props) => {
  const { children, options, ignoreDefaultBreakpoints, renderNavigation } =
    props
  const { breakpoints, ...restOptions } = options || {}
  const sliderBreakpoints = ignoreDefaultBreakpoints ? {} : defaultBreakpoints
  const [currentSlide, setCurrentSlide] = useState(restOptions.initial || 0)

  const sliderOptions = {
    slides: {
      perView: 3,
      spacing: 20,
    },
    breakpoints: { ...sliderBreakpoints, ...breakpoints },
    renderMode: 'performance',
    ...restOptions,
  } as KeenSliderOptions

  const [ref, internalSlider] = useKeenSlider<HTMLDivElement>(sliderOptions)

  useEffect(() => {
    internalSlider.current?.update(sliderOptions)
    internalSlider.current?.moveToIdx(restOptions.initial || 0)
  }, [children])

  const slideRight = () => {
    internalSlider.current?.next()
  }

  const slideLeft = () => {
    internalSlider.current?.prev()
  }

  return (
    <>
      {renderNavigation && renderNavigation(slideLeft, slideRight)}
      <div ref={ref} className="keen-slider">
        {children}
      </div>
    </>
  )
}
