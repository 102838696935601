import { Image } from '@/v2/components/atoms/image/image'
import cx from 'classnames'
import s from './hero.module.css'
import Link from 'next/link'

type HeroProps = {
  content: React.ReactNode
  imageSrc: string
  imageAlt: string
  imageChipText?: string
  contentFrameBgColor?: string
  shorterMobileImage?: boolean
  shorterContent?: boolean
  reverse?: boolean
  heroImageRedirectUrl?: string
  imageLoading?: 'lazy' | 'eager'
}

export const V2Hero = ({
  content,
  imageChipText,
  imageSrc,
  imageAlt,
  contentFrameBgColor,
  reverse = false,
  shorterMobileImage = false,
  shorterContent = false,
  heroImageRedirectUrl,
  imageLoading,
}: HeroProps) => {
  return (
    <div
      className={cx(s.heroContainer, {
        [s.reverse]: reverse,
      })}
    >
      <div
        className={s.heroCardContainer}
        style={{ backgroundColor: contentFrameBgColor || '#F5F5F6' }}
      >
        <div
          className={cx(s.heroContent, {
            [s.shorterContent]: shorterContent,
            [s.reverse]: reverse,
          })}
        >
          {content}
        </div>
      </div>

      <div className={cx(s.imageContainer)}>
        {heroImageRedirectUrl ? (
          <Link href={heroImageRedirectUrl}>
            <Image
              className={cx(s.heroImage, {
                [s.shorterMobileImage]: shorterMobileImage,
              })}
              src={imageSrc}
              alt={imageAlt}
              width={400}
              height={700}
              loading={imageLoading}
              fit
            />
          </Link>
        ) : (
          <Image
            className={cx(s.heroImage, {
              [s.shorterMobileImage]: shorterMobileImage,
            })}
            loading={imageLoading}
            src={imageSrc}
            alt={imageAlt}
            width={400}
            height={700}
            fit
          />
        )}
        {imageChipText && (
          <div
            className={cx(s.imageChip, {
              [s.reverse]: reverse,
            })}
          >
            {imageChipText}
          </div>
        )}
      </div>
    </div>
  )
}
