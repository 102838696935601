'use client'

import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react'
import { getCookie, setCookie } from 'cookies-next'

type FeatureFlags = 'navBar' | 'articleSubcategories' | 'hideArticleTags'

type FeatureFlagContextType = {
  enable: (opts?: OptionsType) => void
  disable: (opts?: OptionsType) => void
  isEnabled: (opts: FeatureFlags | FeatureFlags[]) => boolean
}

export const FeatureFlagContext = createContext<
  FeatureFlagContextType | undefined
>(undefined)

type OptionsType = {
  navBar: boolean
  articleSubcategories: boolean
  hideArticleTags: boolean
}

const defaultOptions = {
  navBar: false,
  articleSubcategories: false,
  hideArticleTags: false,
} satisfies OptionsType

const COOKIE_KEY = `feature_flag`

export const FeatureFlagProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [options, setOptions] = useState<OptionsType>(defaultOptions)
  const getFlagsFromCookies = (): string[] => {
    const cookie = getCookie(COOKIE_KEY) as string | undefined
    return cookie ? cookie.split(',') : []
  }

  useEffect(() => {
    const cookieFlags = getFlagsFromCookies()

    setOptions((prev) => {
      return Object.keys(prev).reduce((acc, key) => {
        acc[key as keyof OptionsType] = cookieFlags.includes(key)
        return acc
      }, {} as OptionsType)
    })
  }, [])

  const updateFlagsInCookies = (updatedOptions: OptionsType) => {
    const activeFlags = Object.keys(updatedOptions).filter(
      (key) => updatedOptions[key as keyof OptionsType]
    )
    setCookie(COOKIE_KEY, activeFlags.join(','), { maxAge: 60 * 60 * 24 * 365 })
  }

  const enable = useCallback((opts?: OptionsType) => {
    if (!opts) {
      setOptions((prev) => {
        const updated = Object.keys(prev).reduce((acc, key) => {
          acc[key as keyof OptionsType] = true
          return acc
        }, {} as OptionsType)
        updateFlagsInCookies(updated)
        return updated
      })
      return
    }

    setOptions((prev) => {
      const updated = { ...prev, ...opts }
      updateFlagsInCookies(updated)
      return updated
    })
  }, [])

  const disable = useCallback((opts?: OptionsType) => {
    if (!opts) {
      setOptions((prev) => {
        const updated = Object.keys(prev).reduce((acc, key) => {
          acc[key as keyof OptionsType] = false
          return acc
        }, {} as OptionsType)
        updateFlagsInCookies(updated)
        return updated
      })
      return
    }

    setOptions((prev) => {
      const updated = { ...prev, ...opts }
      updateFlagsInCookies(updated)
      return updated
    })
  }, [])

  const isEnabled = useCallback(
    (opts: string | string[]) => {
      if (typeof opts === 'string') {
        return options[opts as keyof OptionsType]
      }

      return opts.every((opt) => options[opt as keyof OptionsType])
    },
    [options]
  )

  useEffect(() => {
    ;(window as any).__rmwFeatureFlag = {
      enable,
      disable,
      isEnabled,
    }
  }, [enable, disable, isEnabled])

  return (
    <FeatureFlagContext.Provider
      value={{
        enable,
        disable,
        isEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
