import { FeatureFlagContext } from '@/context/feature-flag-context/feature-flag-context'
import { useContext } from 'react'

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext)
  if (context === undefined) {
    throw new Error(
      'RMW dev error: useFeatureFlag must be used within a FeatureFlagProvider'
    )
  }

  return context
}
