'use client'

import { Fragment } from 'react'
import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import s from './select.module.css'
import cx from 'classnames'
import { DropdownButton } from '@/v2/components/atoms/dropdown-button/dropdown-button'

type OptionItem = {
  value: number | string
  label: string
}

export interface SelectProps<T extends OptionItem> {
  label: string
  options: T[]
  onChange: (value: any) => void
  multiple?: boolean
  value?: T['value'] | T['value'][]
  disabled?: boolean
  className?: string
}

export const Select = <T extends OptionItem>({
  label,
  options,
  onChange,
  multiple,
  value,
  disabled,
  className,
}: SelectProps<T>) => {
  const defaultValue = multiple ? [] : null
  const numberOfSelectedOptions = Array.isArray(value)
    ? options.filter((option) => value.includes(option.value)).length
    : 0
  const multipleLabel =
    numberOfSelectedOptions > 0 ? `(${numberOfSelectedOptions})` : ''

  return (
    <div className={cx(s.wrapper, className)}>
      <Listbox
        value={value || defaultValue}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
      >
        <DropdownButton
          label={`${label} ${multipleLabel}`}
          className={s.button}
        />
        <Transition
          as={Fragment}
          leave={s.transitionLeave}
          leaveFrom={s.opacity1}
          leaveTo={s.opacity0}
          enterFrom={s.opacity0}
          enterTo={s.opacity1}
        >
          <ListboxOptions className={s.options} static>
            {options.map((o) => {
              return (
                <ListboxOption
                  style={{ width: '100%' }}
                  key={o.value}
                  value={o.value}
                >
                  {({ selected, active }) => {
                    return (
                      <div
                        className={cx(s.option, {
                          [s.selected]: selected,
                          [s.active]: active,
                        })}
                      >
                        <span>{o.label}</span>
                      </div>
                    )
                  }}
                </ListboxOption>
              )
            })}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  )
}
